.search {
  width: 100%;
  height: 100%;
  position: relative;

  .input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    font-family: LatoRegular;
    font-size: 15px;
    color: #060606;
    background-color: inherit;
    padding: 0 5px;
    overflow: hidden;
    border: none;
    outline: none;

    &::placeholder {
      color: #999;
    }
  }

  .search {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 5px;
    top: 7px;
    background-image: url(IMAGES/icons/search_default.svg);
    background-position: center;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    z-index: 2;

    &.active {
      background-image: url(IMAGES/icons/search_active.svg);
    }
  }

  .close {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 5px;
    top: 7px;
    background-image: url(IMAGES/icons/close.svg);
    background-position: center;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    z-index: 2;
    cursor: pointer;
  }
}
